import React from 'react';
import { Box, Text, Image, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import WhyWeBoxitLayout from './_layout';
import { Hide } from '../../components/hide';

import Step1 from '../../icons/four-steps-turorial-step-1.svg';
import Step2 from '../../icons/four-steps-turorial-step-2.svg';
import Step3 from '../../icons/four-steps-turorial-step-3.svg';
import Step4 from '../../icons/four-steps-turorial-step-4.svg';

const BoxContent = ({ children }) => (
  <Box
    my="auto"
    mx="auto"
    py={['30px', '0']}
    px={['0', '60px']}
    width="1"
    color="blues.peacock"
    fontSize={['14px', '18px', '20px']}
    lineHeight="1.5"
    textAlign="left"
  >
    {children}
  </Box>
);

const BoxContentTitle = ({ children }) => (
  <Text
    color="blues.twilight"
    fontSize={['20px', '38px', '56px']}
    fontWeight="bold"
    lineHeight="1.8"
    letterSpacing={[0, '-0.8px']}
  >
    {children}
  </Text>
);

const Step = ({ lastStep = false, imgLeft, imgRight, title, subtitle }) => (
  <>
    <Hide tablet desktop>
      <Flex flexDirection="row" flexWrap="wrap" alignItems="top">
        {imgLeft && <Image src={imgLeft} width="100%" />}
        {imgRight && <Image src={imgRight} width="100%" />}
        <BoxContent>
          <BoxContentTitle>{title}</BoxContentTitle>
          {subtitle}
        </BoxContent>
      </Flex>
    </Hide>

    <Hide mobile>
      <Flex
        css={{
          border: 'solid 1px #cfd4dc',
          borderBottom: lastStep ? 'solid 1px #cfd4dc' : 'none',
        }}
      >
        {imgLeft && <Image src={imgLeft} />}
        <BoxContent>
          <BoxContentTitle>{title}</BoxContentTitle>
          {subtitle}
        </BoxContent>
        {imgRight && <Image src={imgRight} />}
      </Flex>
    </Hide>
  </>
);

const FourStepsTutorial = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <WhyWeBoxitLayout
      subtitle={t('why_we_boxit.4_steps.menu.title')}
      {...props}
    >
      <Page
        title={t('why_we_boxit.4_steps.page.title')}
        subtitle={t('why_we_boxit.4_steps.page.subtitle')}
      >
        <Box
          height="120px"
          mt={['25px', '44px']}
          py={['25px', 0]}
          backgroundColor="blues.peacock"
          css={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
        >
          <Text
            mx="auto"
            color="white"
            fontWeight="bold"
            letterSpacing="0.5"
            textAlign="center"
            my={['28x', '36px', '36px']}
            fontSize={['28x', '36px', '36px']}
          >
            {t('why_we_boxit.4_steps.content.header')}
          </Text>
        </Box>
        <Step
          imgLeft={Step1}
          title={t('why_we_boxit.4_steps.content.steps.0.title')}
          subtitle={t('why_we_boxit.4_steps.content.steps.0.subtitle')}
        />
        <Step
          imgRight={Step2}
          title={t('why_we_boxit.4_steps.content.steps.1.title')}
          subtitle={t('why_we_boxit.4_steps.content.steps.1.subtitle')}
        />
        <Step
          imgLeft={Step3}
          title={t('why_we_boxit.4_steps.content.steps.2.title')}
          subtitle={t('why_we_boxit.4_steps.content.steps.2.subtitle')}
        />
        <Step
          lastStep={true}
          imgRight={Step4}
          title={t('why_we_boxit.4_steps.content.steps.3.title')}
          subtitle={t('why_we_boxit.4_steps.content.steps.3.subtitle')}
        />
      </Page>
    </WhyWeBoxitLayout>
  );
};

export default FourStepsTutorial;
